<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <router-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <img :src="appLogoImage" alt="logo" style="height:32px">
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style>

</style>
