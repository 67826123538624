export default [
  {
    header: 'Tools',
    icon: 'psh-toolbox',
    children: [
      {
        title: 'Buy Shipping Label',
        icon: 'psh-labeling',
        route: 'shipping-label',
        resource: 'resource_tools_shipping_label',
        action: 'read',
      },
      {
        title: 'Manual Barcode Print',
        icon: 'feather-printer',
        route: 'print-label',
        resource: 'resources_common',
        action: 'read',
      },
      {
        title: 'Barcode Scanning',
        icon: 'feather-check-square',
        route: 'tools-batch-verify',
        resource: 'resources_common',
        action: 'read',
      },
    ],
  },
]
