export default [
  {
    header: 'Inbound',
    icon: 'feather-log-in',
    children: [
      {
        title: 'Shipment Plans',
        route: 'uploaded-batches',
        icon: 'bi-box-seam',
        resource: 'resource_ship_shipment_batch',
        action: 'read',
      },
      {
        title: 'Delivered Packages',
        route: 'route-unload-scan-batch',
        icon: 'bi-box-arrow-in-down',
        resource: 'resource_inbound_package_scan_receive_batch',
        action: 'read',
      },
      {
        title: 'Item Sort',
        route: 'route-unload-item-sort',
        icon: 'bi-upc-scan',
        resource: 'resource_inbound_item_sort',
        action: 'read',
      },
      {
        title: 'Sorted Batches',
        route: 'route-inbound-sorted-batches',
        icon: 'bi-collection',
        resource: 'resource_inbound_item_sorted_batches',
        action: 'read',
      },
      {
        title: 'Problem Items',
        route: 'problem-management',
        icon: 'feather-alert-triangle',
        resource: 'resource_management_problem_management',
        action: 'read',
      },
    ],
  },
]
