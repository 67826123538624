export default [
  {
    header: 'WMS',
    icon: 'psh-warehouse2',
    children: [
      {
        title: 'Set up Locations',
        route: 'warehouse-location',
        resource: 'resource_management_warehouse_location',
        action: 'read',
        icon: 'feather-map-pin',
      },
      {
        title: 'Location Actions',
        route: 'warehouse-action',
        resource: 'resource_management_warehouse_location',
        action: 'read',
        icon: 'psh-package-3',
      },
      {
        title: 'Label Designer',
        route: 'location-barcode-designer',
        resource: 'resource_management_warehouse_location',
        action: 'read',
        icon: 'psh-qr-scan',
      },
    ],
  },
]
