export default [
  {
    header: 'Dashboards',
    icon: 'feather-home',
    children: [
      {
        title: 'Dashboard',
        route: 'dashboard-statistics',
        icon: 'feather-bar-chart-2',
        resource: 'resource_dashboards_dashboard_snapshot',
        action: 'read',
      },
      {
        title: 'Item Tracking',
        route: 'dashboard-summary',
        icon: 'psh-item-tracking3',
        resource: 'resource_dashboards_dashboard_summary',
        action: 'read',
      },
      {
        title: 'Business Metrics',
        route: 'dashboard-basic-reporting',
        icon: 'bi-graph-up',
        resource: 'resource_dashboards_dashboard_basic_stats',
        action: 'read',
      },
      {
        title: 'Leader Board',
        route: 'dashboard-worker-performance',
        icon: 'psh-leader-board',
        resource: 'resource_dashboards_dashboard_basic_stats',
        action: 'read',
      },
    ],
  },
]
