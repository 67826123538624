<template>
  <div class="d-flex justify-content-between align-items-center ml-auto">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item ">
        <div
          class="nav-link"
          role="button"
          @click="toggleVerticalMenuActive"
        >
          <p-icon name="feather-menu" size="22px" />
        </div>
      </li>
    </ul>
      <!-- <locale /> -->
    <b-navbar-nav class="nav align-items-center">
      <!-- <search-bar /> -->
      <!-- <cart-dropdown />  -->
      <system-notification />
      <user-dropdown />
      <app-customizer-open />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
// BLink,
// BNavbarNav,
} from 'bootstrap-vue'
// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
// import DarkToggler from './components/DarkToggler.vue'
// import SearchBar from './components/SearchBar.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import SystemNotification from './components/SystemNotification.vue'
import UserDropdown from './components/UserDropdown.vue'
import AppCustomizerOpen from './components/AppCustomizerOpen.vue'

export default {
  components: {
    // BLink,

    // Navbar Components
    // BNavbarNav,
    // Bookmarks,
    // Locale,
    // DarkToggler,
    // SearchBar,
    // CartDropdown,
    SystemNotification,
    AppCustomizerOpen,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

}
</script>
