<template>
  <li
    v-if="$can(item.action, item.resource)"
    class="nav-item"
    :class="{'sidebar-group-active active': isActive}"
  >
    <router-link
      class="nav-link"
      :to="createRoute()"
    >
      <p-icon :name="item.icon" />
      <span>{{ item.title }}</span>
    </router-link>
  </li>
</template>

<script>
import useHorizontalNavMenuHeaderLink from './useHorizontalNavMenuHeaderLink'
import mixinHorizontalNavMenuHeaderLink from './mixinHorizontalNavMenuHeaderLink'

export default {
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(props.item)

    function createRoute() {
      const route = {}
      route.name = props.item.route
      if (props.item.params) {
        route.params = { params: props.item.params }
      }
      return route
    }

    return {
      createRoute,
      isActive,
      updateIsActive,
    }
  },
}
</script>
