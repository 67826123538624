<template>
  <router-link
    class="navbar-brand align-self-center"
    to="/"
  >
    <span class="brand-logo">
      <img :src="appLogoImage" alt="logo" style="height:32px">
    </span>
  </router-link>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
