export default [
  {
    header: 'Settings',
    icon: 'feather-settings',
    children: [
      {
        title: 'General',
        route: 'general-settings',
        icon: 'psh-exchange',
        resource: 'resource_management_finance_tools',
        action: 'read',
      },
      {
        title: 'Printer Settings',
        route: 'printer-settings',
        icon: 'feather-printer',
        resource: 'resource_printer_settings',
        action: 'read',
      },
      {
        title: 'Fee Settings',
        route: 'fee-settings',
        icon: 'psh-exchange',
        resource: 'resource_management_global_settings',
        action: 'read',
      },
      {
        title: 'Subscriptions',
        route: 'route-notification-subscribe',
        icon: 'feather-bell',
        resource: 'resource_mail_management_mail_topic',
        action: 'read',
      },
    ],
  },
]
