export default [
  {
    header: 'Management',
    icon: 'feather-tool',
    children: [
      {
        title: 'Clients',
        route: 'company-management',
        icon: 'feather-home',
        resource: 'resource_clients_companies',
        action: 'read',
      },
      {
        title: 'Role Management',
        route: 'route-management-roles-list',
        icon: 'feather-key',
        resource: 'resource_management_roles',
        action: 'read',
      },
      {
        title: 'Service Charge Plans',
        route: 'route-plans-customer-plans',
        icon: 'feather-columns',
        resource: 'resource_management_plans',
        action: 'read',
      },
      {
        title: 'Cost Groups',
        route: 'route-cost-group',
        icon: 'feather-sidebar',
        resource: 'resource_management_cost_group',
        action: 'read',
      },
      {
        title: 'Prep Materials',
        route: 'route-material-items',
        icon: 'psh-logistic-3',
        resource: 'resource_management_prep_materials_materials',
        action: 'read',
      },
      {
        title: 'Tasks',
        icon: 'bi-list-task',
        children: [
          {
            title: 'Task Management',
            route: 'route-task-management',
            icon: 'bi-list-task',
            resource: 'resource_customer_account_no',
            action: 'read',
          },
          {
            title: 'Task Calendar',
            route: 'route-task-calendar',
            icon: 'bi-calendar-check',
            resource: 'resource_customer_account_no',
            action: 'read',
          },
        ],
      },
      {
        title: 'Support Tickets',
        route: 'route-tickets',
        icon: 'bi-ticket',
        resource: 'resource_admin',
        action: 'read',
      },
      {
        title: 'Files',
        route: 'file-browser-management',
        icon: 'feather-folder',
        resource: 'resource_management_file_management',
        action: 'read',
      },
    ],
  },
]
